import React from 'react';
import styled from 'styled-components';
import { Large } from '../../text';
import { EmailInput } from '../../EmailInput';

const Label = styled(Large).attrs(props => ({ as: 'label' }))`
  margin-bottom: ${({ theme }) => theme.spacing(1)};
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const waitlistEndpoint = '/api/newsletter/subscribe';
const defaultSubmitEmail = async email => {
  const body = await post(waitlistEndpoint, { email });
  return body;
};

export const EmailModule = () => {
  const [email, setEmail] = React.useState('');

  const submitEmail = async (e) => {
    e.preventDefault();

    const body = await post('/api/newsletter/subscribe', { email });
    const { error } = body;

    if (error && error.detail) {
      window.alert(error.detail);
    } else if (error) {
      window.alert('Oops, there was an error; please try again');
    } else {
      setEmail('');
    }
  };

  return (
    <Form onSubmit={submitEmail}>
      <Label weight="medium" htmlFor={'subscribe-email'}>
        Subscribe for the latest
      </Label>
      <EmailInput
        value={email}
        onChange={email => setEmail(email)}
        inputProps={{ id: 'subscribe-email' }}
      />
    </Form>
  );
};
