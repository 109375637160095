import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import SEO, { buildBlogPostSchema } from '../components/seo';
import Layout from '../components/layout';
import { Grid } from '../components/grid';
import { Author } from '../components/blog/blogPost/Author';
import { renderer } from '../components/blog/blogPost/renderer';
import { EmailModule } from '../components/blog/blogPost/EmailModule';
import { TwitterSocialIcon } from '../components/footer/components';
import { generateTimeElement } from '../utils/generateDateString';
import { usePageMetaAttributes } from '../utils/usePageMetaAttributes';
import { Display, Huge, Small, Regular } from '../components/text';

const twitterPrefix = 'https://twitter.com/intent/tweet?text=';

const pageQuery = graphql`
  query($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      ...BlogPost
      body {
        json
      }
      author {
        name
        positionTitle
        twitterHandle
        bio {
          bio
        }
        profilePicture {
          file {
            url
          }
        }
      }
    }
  }
`;

const BlogPost = ({ data }) => {
  const { title, slug, subtitle, body, author, createdAt, updatedAt } = data.contentfulBlogPost;

  const meta = usePageMetaAttributes('blog');
  const [headerInfo, setHeaderInfo] = React.useState([]);

  React.useEffect(() => {
    const headers = document.querySelectorAll('.sectionHeader');
    const headerInfo = [];

    headers.forEach(header => headerInfo.push({ id: header.id, text: header.textContent }));
    setHeaderInfo(headerInfo);
  }, []);

  return (
    <Layout>
      <SEO
        title={title}
        image={meta.image}
        imageWidth={meta.imageWidth}
        imageHeight={meta.imageHeight}
        description={subtitle.subtitle}
        schemaMarkup={JSON.stringify(buildBlogPostSchema(data.contentfulBlogPost, meta))}
      />
      <Grid>
        <article css={{ width: '100%', margin: '0 auto', maxWidth: '666px' }}>
          <Header>
            <BlogBackLink href="/blog">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10 8L6 12L10 16" stroke="#8894A8" strokeLinecap="round" strokeLinejoin="round"></path>
                  <path d="M6.86407 11.9719L18.2489 11.9719" stroke="#8894A8" strokeLinecap="round" strokeLinejoin="round"></path>
                </svg>
                <div style={{ marginLeft: '4px' }}>Namebase Blog</div>
            </BlogBackLink>
            <ArticlePublishDate colored="greyText">
              {generateTimeElement(createdAt, updatedAt)}
            </ArticlePublishDate>
            <Display as="h1">{title}</Display>
            <Huge as="h2">{subtitle.subtitle}</Huge>
            <Author {...author} minimal />
            {headerInfo.length > 0 ? (
              <ArticleNavigation>
                <ArticleNavigationTitle />
                <ArticleNavigationLinks>
                  {headerInfo.map(({ id, text }, index) => (
                    <li>
                      <ArticleNavigationLink key={id} href={'#' + id}>
                        <span>{index + 1}.</span>
                        {text}
                      </ArticleNavigationLink>
                    </li>
                  ))}
                </ArticleNavigationLinks>
              </ArticleNavigation>
            ) : null}
          </Header>
          <ArticleBody as={headerInfo.length > 0 ? 'section' : 'div'}>
            {documentToReactComponents(body.json, renderer)}
          </ArticleBody>
          <ArticleFooter>
            <TwitterSocialIcon
              rel="noopener noreferrer"
              href={`${twitterPrefix}${encodeURIComponent(
                `${subtitle.subtitle} https://namebase.io/blog/${slug}`
              )}`}
              target="_blank"
            />
            <div>
              <Divider />
              <Author {...author} />
              <Divider />
            </div>
          </ArticleFooter>
        </article>
      </Grid>
    </Layout>
  );
};

export { pageQuery };
export default BlogPost;

const Header = styled.header`
  h1,
  h2 {
    margin-top: ${({ theme }) => theme.spacing(2)};
    font-family: 'Roboto Variable';
    font-variation-settings: 'wght' 500;
  }
`;

const BlogBackLink = styled(Small).attrs(props => ({ as: 'a', colored: 'greyText' }))`
  margin-top: ${props => props.theme.spacing(2)};
  display: flex;
  width: max-content;
  align-items: center;
  color: #8894a8;
  &:hover {
    color: #222;
  }
`;

const ArticlePublishDate = styled(Small)`
  margin-top: ${props => props.theme.spacing(10)};
  margin-bottom: ${props => props.theme.spacing(2)};

  @media ${props => props.theme.breakpoints.sm} {
    margin-top: ${props => props.theme.spacing(6)};
  }
`;

const ArticleNavigation = styled.nav.attrs(props => ({
  'aria-labelledby': 'article-navigation',
}))`
  border: 1px solid ${({ theme }) => theme.color.grey90};
  padding: ${({ theme }) => theme.spacing(2)};
  border-radius: ${({ theme }) => theme.borderRadii.standard};
`;

const ArticleNavigationTitle = styled(Regular).attrs(props => ({
  id: 'article-navigation',
  as: 'h3',
  weight: 'bold',
  children: 'Contents',
}))`
  margin-bottom: ${({ theme }) => theme.spacing(1)};
`;

const ArticleNavigationLinks = styled.ol`
  & > * + * {
    margin-top: ${({ theme }) => theme.spacing(0.5)};
  }
`;

const ArticleNavigationLink = styled(Regular).attrs(props => ({ as: 'a', colored: 'nbPrimary' }))`
  &:hover {
    color: ${({ theme }) => theme.color.blue50};
  }

  & span {
    color: ${({ theme }) => theme.color.brandDark};
    margin-right: ${({ theme }) => theme.spacing(1)};
  }
`;

const ArticleBody = styled.div``;

const ArticleFooter = styled.footer`
  padding-top: ${({ theme }) => theme.spacing(5)};
  padding-bottom: ${({ theme }) => theme.spacing(18)};

  & > * + * {
    margin-top: ${({ theme }) => theme.spacing(7)};
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 2px;
  background: ${({ theme }) => theme.color.nbPrimary};
`;
