import React from 'react';
import moment from 'moment';

const formatDate = dateString => {
  return moment(dateString).format("MMM DD, YYYY");
};

export const generateTimeElement = (dateCreated, dateUpdated) => {
  if (dateUpdated !== dateCreated) {
    return <time dateTime={dateUpdated}>Updated {formatDate(dateUpdated)}</time>
  } else {
    return <time dateTime={dateCreated}>{formatDate(dateCreated)}</time>
  }
}
