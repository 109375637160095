import React from 'react';
import styled from 'styled-components';
import { Button } from './Button';

const Wrapper = styled.div`
  width: 100%;
  display: flex;

  @media ${props => props.theme.breakpoints.xs} {
    flex-direction: column;
  }
`;

const Input = styled.input`
  padding: 0 ${props => props.theme.spacing(2)};
  border: 1px solid ${props => props.theme.color.borderGrey};
  border-right: none;
  border-radius: ${props => props.theme.borderRadii.standard} 0 0
    ${props => props.theme.borderRadii.standard};
  outline: none;
  flex-grow: 1;
  height: ${props => props.theme.spacing(6)};
  font-size: ${props => props.theme.text.regular.size};
  line-height: ${props => props.theme.text.regular.lineHeight};

  &::placeholder {
    color: ${props => props.theme.color.placeholderText};
  }

  @media ${props => props.theme.breakpoints.xs} {
    margin-bottom: ${props => props.theme.spacing(1)};
    border-right: 1px solid ${props => props.theme.color.borderGrey};
    border-radius: ${props => props.theme.borderRadii.standard};
  }
`;

const EmailButton = styled(Button)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  font-weight: 700;
  @media ${props => props.theme.breakpoints.xs} {
    border-radius: ${props => props.theme.borderRadii.standard};
  }
`;

export const EmailInput = ({ value, onChange, inputProps }) => (
  <Wrapper>
    <Input
      {...inputProps}
      type="email"
      value={value}
      onChange={e => onChange(e.target.value)}
      placeholder="Your email"
    />
    <EmailButton type="submit" size="large">
      Subscribe
    </EmailButton>
  </Wrapper>
);
