import React from 'react';
import styled, { css } from 'styled-components';
import { Small, Large, Regular } from '../../text';

export const Author = ({
  bio,
  name,
  minimal = false,
  twitterHandle,
  positionTitle,
  profilePicture,
}) => {
  const renderAuthorAnchor = content => {
    const href = Boolean(twitterHandle) ? `https://twitter.com/${twitterHandle}` : '/about';
    return (
      <a rel="author noopener noreferrer" href={href} target="_blank">
        <Small weight="medium">{content}</Small>
      </a>
    );
  };

  const renderAddressContent = () => {
    if (minimal) {
      return (
        <div css={{ display: 'flex', alignItems: 'center' }}>
          <ProfilePicture
            src={profilePicture.file.url}
            alt={`${name}'s profile picture`}
            size={4}
          />
          {renderAuthorAnchor(name)}
        </div>
      );
    } else {
      return (
        <div>
          <div css={{ display: 'flex' }}>
            <ProfilePicture
              src={profilePicture.file.url}
              alt={`${name}'s profile picture`}
              size={7}
            />
            <AuthorNameAndTwitter>
              <Large weight="medium">{name}</Large>
              <Small style={{ display: 'flex' }}>
                <span>{positionTitle}</span>
                <span>|</span>
                <span>{renderAuthorAnchor(` @${twitterHandle}`)}</span>
              </Small>
            </AuthorNameAndTwitter>
          </div>
          <br />
          <Regular colored="greyText" styled="italic">
            {bio.bio}
          </Regular>
        </div>
      );
    }
  };

  return (
    <Address css={minimal ? minimalPadding : regularPadding}>{renderAddressContent()}</Address>
  );
};

const regularPadding = css`
  padding: ${({ theme }) => theme.spacing(5)} 0;
`;
const minimalPadding = css`
  padding-top: ${({ theme }) => theme.spacing(3)};
  padding-bottom: ${({ theme }) => theme.spacing(4)};
`;

const Address = styled.address`
  *:not(${Regular}) {
    font-style: normal;
  }

  @media ${({ theme }) => theme.breakpoints.md} {
    padding: ${({ theme }) => theme.spacing(2)} 0;
  }
`;

const ProfilePicture = styled.img`
  width: ${({ theme, size }) => theme.spacing(size)};
  height: ${({ theme, size }) => theme.spacing(size)};
  border-radius: 50%;
  margin-right: ${({ theme }) => theme.spacing(2)};
`;

const AuthorNameAndTwitter = styled.div`
  a * {
    color: ${({ theme }) => theme.color.nbPrimary};
  }

  & ${Small} > * + * {
    margin-left: ${({ theme }) => theme.spacing(1)};
  }
`;
