import * as React from 'react';

import Img from 'gatsby-image';
import styled from 'styled-components';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';

import { Large, HeaderSmall } from '../../text';

export const renderer = {
  renderNode: {
    [BLOCKS.QUOTE]: (_, children) => {
      return <Blockquote children={children} />;
    },
    [BLOCKS.UL_LIST]: (_, children) => {
      return <UnorderedList children={children} />;
    },
    [BLOCKS.HEADING_1]: (_, children) => {
      const id = children[0].toLowerCase().trim().replace(/ /g, '-');
      return <H2 id={id} children={children} />;
    },
    [BLOCKS.PARAGRAPH]: (_, children) => {
      return <Paragraph children={children} />;
    },
    [INLINES.HYPERLINK]: ({ data: { uri } }, children) => {
      return <Anchor rel="noopener noreferrer" href={uri} target="_blank" children={children} />;
    },
    [BLOCKS.EMBEDDED_ASSET]: (node, _) => {
      const fields = node.data.target.fields;

      const file = fields.file['en-US'];
      const description = fields.description;

      let descriptionContent = '';
      if (description && description['en-US']) descriptionContent = description['en-US']
      
      const { url, contentType, details } = file;
      if (contentType.includes('image')) {
        const baseUrl = url;
        const urlParams = new URLSearchParams();

        if (details.image.width > 512) {
          urlParams.set('w', 512)
        }

        return <Image src={`${url}?${urlParams.toString()}`} alt={description} />
      } else {
        return null;
      }
    },
  },
};

const Anchor = styled.a`
  color: ${({ theme }) => theme.color.nbPrimary};
  transition: ${({ theme }) => theme.transitions.color};

  &:hover {
    color: ${({ theme }) => theme.color.nbPrimaryDark};
  }
`;

const Paragraph = styled(Large).attrs(props => ({ as: 'p' }))`
  margin-top: ${({ theme }) => theme.spacing(2.5)};
`;

const UnorderedList = styled(Paragraph).attrs(props => ({ as: 'ul' }))`
  list-style: circle;
  padding-left: 1em;

  li {
    padding-left: 0.25em;
  }

  * {
    margin-top: ${({ theme }) => theme.spacing(0.5)};
  }
`;

// positioning from http://nicolasgallagher.com/jump-links-and-viewport-positioning/ for accurate anchor link jumping
const H2 = styled(HeaderSmall).attrs(props => ({
  as: 'h2',
  weight: 'medium',
  className: 'sectionHeader',
}))`
  position: relative;
  /*margin-top: ${({ theme }) => theme.spacing(-2.5)};*/
  border-top: ${({ theme }) => theme.spacing(10)} solid transparent;
  background-clip: padding-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;

  @media ${({ theme }) => theme.breakpoints.md} {
    /*margin-top: ${({ theme }) => theme.spacing(-1)};*/
    border-top: ${({ theme }) => theme.spacing(8)} solid transparent;
  }
`;

const Blockquote = styled(Paragraph).attrs(props => ({
  as: 'blockquote',
}))`
  margin: 0;
  margin-top: ${props => props.theme.spacing(7)};
  border-left: 4px solid ${({ theme }) => theme.color.nbPrimary};
  padding-left: ${({ theme }) => theme.spacing(3)};
  margin-bottom: ${props => props.theme.spacing(4)};
`;

const Image = styled.img`
  margin: 0 auto;
  display: block;
  max-width: 100%;
  padding-top: ${({ theme }) => theme.spacing(2)};
  padding-bottom: ${({ theme }) => theme.spacing(3)};
`;
